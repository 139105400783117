import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { identityMatrix } from 'pdf-lib/cjs/types/matrix';

/**
 * Obtiene los datos de los formularios asociados al usuario actual.
 *
 * @async
 * @function
 * @param {Object} currentUser - Objeto que contiene la información del usuario actual.
 * @param {function} setServicioId - Función para establecer el ID del servicio.
 * @param {function} setLoading - Función para establecer el estado de carga.
 * @returns {Promise<Array<Object>>} - Una promesa que se resuelve con un array de objetos que representan los formularios.
 * @throws {Error} - Se lanza un error si hay problemas al obtener los datos.
 */
export const getFormsData = async (currentUser, setServicioId, setLoading) => {
    const serviciosRef = collection(db, 'servicios');
    const q = query(serviciosRef, where('admins', 'array-contains', currentUser.email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        const primerServicio = querySnapshot.docs[0];

        setServicioId(primerServicio.id);

        const documentsRef = collection(serviciosRef, primerServicio.id, 'forms');
        const formsSnapshot = await getDocs(documentsRef);
        const formsData = formsSnapshot.docs.map((doc) => {
            const cliente = doc.data().cliente ? doc.data().cliente : [];
            return {
                id: doc.id,
                ...doc.data(),
                cliente,
            };
        });

        setLoading(false);
        return formsData;
    }
    return [];
};

/**
 * Elimina un formulario específico.
 *
 * @async
 * @function
 * @param {string} id - ID del formulario que se va a eliminar.
 * @param {string} servicioId - ID del servicio al que pertenece el formulario.
 * @param {function} setFormularios - Función para actualizar la lista de formularios después de la eliminación.
 * @returns {Promise<void>} - Una promesa que se resuelve cuando la eliminación es exitosa.
 * @throws {Error} - Se lanza un error si hay problemas al eliminar el formulario.
 */
export const deleteForm = async (id, servicioId, setFormularios) => {
    try {
        const confirmed = window.confirm('¿Estás seguro que quieres borrar el formulario?');
        if (!confirmed) {
            return;
        }

        console.log(id)
        const documentPath = `servicios/${servicioId}/forms/${id}`;
        const documentRef = doc(db, documentPath);

        await deleteDoc(documentRef);
        setFormularios((prevFormularios) => prevFormularios.filter((formulario) => formulario.id !== id));
    } catch (error) {
        console.error('Error al eliminar el formulario:', error);
    }
};
