import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "testingstuff-47ffc.firebaseapp.com",
  projectId: "testingstuff-47ffc",
  storageBucket: "testingstuff-47ffc.appspot.com",
  messagingSenderId: "439788352619",
  appId: "1:439788352619:web:a9fc656273b1de8a5b2d2e"
};

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_KEY2,
//   authDomain: "appsecoex.firebaseapp.com",
//   projectId: "appsecoex",
//   storageBucket: "appsecoex.appspot.com",
//   messagingSenderId: "438187341701",
//   appId: "1:438187341701:web:dd1929c5f49bde22acf6ec",
//   measurementId: "G-BYNVQFYYB2"
// };

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
