import { getFirestore, collection, getDocs, addDoc, query, where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../../firebase';

const functions = getFunctions();

export const getExpoTokensForVigilantes = async (numTiendas, servicioId) => {
  try {
    if (!servicioId) {
      throw new Error('No se ha encontrado un servicio asociado al usuario.');
    }

    console.log('Buscando tokens para servicioId:', servicioId);
    console.log('Números de tienda:', numTiendas);

    const vigilantesRef = collection(db, 'servicios', servicioId, 'vigilantes');
    let vigilantesSnapshot;

    if (numTiendas) {
      const q = query(vigilantesRef, where('tiendas.numero', 'in', numTiendas));
      vigilantesSnapshot = await getDocs(q);
    } else {
      vigilantesSnapshot = await getDocs(vigilantesRef);
    }

    console.log('Vigilantes encontrados:', vigilantesSnapshot.size);

    const vigilantesData = vigilantesSnapshot.docs.map(doc => {
      const data = doc.data();
      console.log('Vigilante:', doc.id, 'Token:', data.expoToken || 'No tiene token');
      return data;
    });

    const expoTokens = vigilantesData
      .map(data => data.expoToken)
      .filter(token => token && token.startsWith('ExponentPushToken'));

    console.log('Tokens válidos encontrados:', expoTokens.length);

    if (expoTokens.length === 0) {
      const vigilantesSinToken = vigilantesData
        .filter(data => !data.expoToken)
        .map(data => data.tipVS || data.email || 'Desconocido');
      
      console.log('Vigilantes sin token:', vigilantesSinToken);
      throw new Error(`No se encontraron tokens válidos. Vigilantes sin token: ${vigilantesSinToken.join(', ')}`);
    }

    return expoTokens;
  } catch (error) {
    console.error('Error detallado al obtener tokens:', error);
    throw error;
  }
};

export const sendNotificationToStore = async (notificationTitle, notificationMessage, numTienda, servicioId) => {
  try {
    console.log('Enviando notificación a tienda específica');
    console.log('ServiceID:', servicioId, 'Tienda:', numTienda);
    
    const expoTokens = await getExpoTokensForVigilantes(numTienda, servicioId);
    console.log('Tokens encontrados para la tienda:', expoTokens.length);

    const sendNotification = httpsCallable(functions, 'sendPushNotification');
    const result = await sendNotification({
      tokens: expoTokens,
      title: notificationTitle,
      body: notificationMessage,
      data: {
        type: 'store',
        storeNumber: numTienda,
        timestamp: new Date().toISOString()
      }
    });

    const to = determineToValue(numTienda);
    await addNotification(notificationTitle, notificationMessage, to, new Date().toISOString(), servicioId);

    console.log('Notificación enviada exitosamente a tienda');
    return result.data;
  } catch (error) {
    console.error('Error detallado al enviar notificación a tienda:', error);
    throw error;
  }
};

const determineToValue = (numTienda) => {
  const numTiendaArray = Array.isArray(numTienda) ? numTienda : [numTienda];
  return numTiendaArray.length > 1 ? 'cliente' : 'servicio';
};

export const sendNotification = async (title, body, servicioId) => {
  try {
    console.log('Iniciando envío de notificación general');
    console.log('ServiceID:', servicioId);
    
    const vigilantesRef = collection(db, 'servicios', servicioId, 'vigilantes');
    const vigilantesSnapshot = await getDocs(vigilantesRef);

    console.log('Total vigilantes encontrados:', vigilantesSnapshot.size);

    // Obtener y loguear información de cada vigilante
    const vigilantesInfo = vigilantesSnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        tipVS: data.tipVS,
        hasToken: !!data.expoToken,
        token: data.expoToken
      };
    });

    console.log('Información de vigilantes:', vigilantesInfo);

    const expoTokens = vigilantesInfo
      .filter(v => v.hasToken && v.token.startsWith('ExponentPushToken'))
      .map(v => v.token);

    console.log('Tokens válidos encontrados:', expoTokens.length);

    if (expoTokens.length === 0) {
      // Identificar vigilantes sin token para mejor diagnóstico
      const vigilantesSinToken = vigilantesInfo
        .filter(v => !v.hasToken)
        .map(v => v.tipVS || v.id)
        .join(', ');
      
      throw new Error(`No se encontraron tokens válidos. Vigilantes sin token: ${vigilantesSinToken}`);
    }

    const sendNotification = httpsCallable(functions, 'sendPushNotification');
    console.log('Enviando notificación a', expoTokens.length, 'dispositivos');
    
    const result = await sendNotification({
      tokens: expoTokens,
      title,
      body,
      data: {
        type: 'general',
        timestamp: new Date().toISOString()
      }
    });

    await addNotification(title, body, 'all', new Date().toISOString(), servicioId);
    
    console.log('Notificación enviada exitosamente', result.data);
    return result.data;
  } catch (error) {
    console.error('Error detallado al enviar notificación:', error);
    throw error;
  }
};
const addNotification = async (title, body, to, dateSent, servicioId) => {
  try {
    const notificationsRef = collection(db, 'servicios', servicioId, 'notifications');
    const docRef = await addDoc(notificationsRef, { 
      title, 
      body, 
      to, 
      dateSent,
      createdAt: new Date()
    });
    return docRef.id;
  } catch (error) {
    console.error('Error al agregar la notificación a Firestore:', error);
    throw error;
  }
};