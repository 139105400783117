import { db } from '../../firebase';
import { collection, doc, setDoc, query, where, getDocs, updateDoc, addDoc, getDoc, deleteDoc } from 'firebase/firestore'; // Asegúrate de importar los métodos necesarios


export const createCuadrante = async (clienteId, currentService, servicioId, userId) => {
  try {
    console.log("cliente ", clienteId, " tienda ", servicioId)
    // Verifica si ya existe un cuadrante para el mismo cliente y servicio
    const existingCuadranteQuery = await getDocs(query(
      collection(db, 'servicios', currentService.id, 'cuadrantes'),
      where('servicioId', '==', servicioId)
    ));

    console.log("check", servicioId)
    // Si ya existe un cuadrante para el mismo cliente y servicio, actualizar el cuadrante existente
    if (!existingCuadranteQuery.empty) {
      const cuadranteDoc = existingCuadranteQuery.docs[0];
      console.log("check2", servicioId)

      return cuadranteDoc.id; // Devolver el ID del cuadrante actualizado
    }

    // Si no existe un cuadrante para el mismo cliente y servicio, crea uno nuevo
    const cuadranteRef = await setDoc(doc(collection(db, 'servicios', currentService.id, 'cuadrantes')), {
      clienteId: clienteId,
      userId: userId,
      servicioId: servicioId,
      createdAt: new Date(),
    });

    // Devuelve el ID del nuevo cuadrante
    return cuadranteRef.id;
  } catch (error) {
    console.error('Error al crear el cuadrante:', error);
    throw error; // Propaga el error para que pueda ser manejado por el componente
  }
};


export const agregarTurno = async (cuadranteId, turnoData, currentService) => {
  try {
    console.log("check")
    // Obtener la referencia al documento del cuadrante
    const cuadranteRef = doc(db, 'servicios', currentService.id, 'cuadrantes', cuadranteId);

    // Agregar el turno al subdocumento "turnos" dentro del cuadrante
    const turnoRef = await addDoc(collection(cuadranteRef, 'turnos'), turnoData);
    console.log('Turno agregado con ID: ', turnoRef.id);
    return turnoRef.id; // Devuelve el ID del turno agregado
  } catch (error) {
    console.error('Error al agregar turno: ', error);
    throw error; // Propaga el error para que pueda ser manejado por el componente
  }
};

export const obtenerTurnosPorCuadrante = async (cuadranteId, currentService) => {
  try {
    console.log("cuandrate id ", cuadranteId)
    // Obtener la referencia al documento del cuadrante
    const cuadranteRef = doc(db, 'servicios', currentService.id, 'cuadrantes', cuadranteId);

    // Obtener todos los turnos dentro de la subcolección "turnos" del cuadrante
    const turnosSnapshot = await getDocs(collection(cuadranteRef, 'turnos'));

    // Crear un array para almacenar los datos de los turnos
    const turnos = [];

    // Iterar sobre los documentos de los turnos y almacenarlos en el array
    turnosSnapshot.forEach((doc) => {
      const turnoData = doc.data();
      // Convertir la fecha y hora de inicio del turno a un timestamp
      const inicioTurnoTimestamp = new Date(`${turnoData.fecha}T${turnoData.inicioTurno}:00`).getTime();
      // Convertir la fecha y hora de fin del turno a un timestamp
      const finTurnoTimestamp = new Date(`${turnoData.fecha}T${turnoData.finTurno}:00`).getTime();

      turnos.push({
        id: doc.id,
        ...doc.data(),
        inicioTurnoTimestamp: inicioTurnoTimestamp,
        finTurnoTimestamp: finTurnoTimestamp
      });
    });

    console.log("turnos ", turnos)
    return turnos; // Devuelve el array de turnos
  } catch (error) {
    console.error('Error al obtener turnos por cuadrante:', error);
    throw error; // Propaga el error para que pueda ser manejado por el componente
  }
};

export const actualizarTurno = async (cuadranteId, turnoId, turnoData, currentService) => {
  try {
    // Obtener la referencia al documento del cuadrante y el turno específico
    const cuadranteRef = doc(db, 'servicios', currentService.id, 'cuadrantes', cuadranteId);
    const turnoRef = doc(cuadranteRef, 'turnos', turnoId);

    // Actualizar los datos del turno específico
    await updateDoc(turnoRef, turnoData);

    console.log('Turno actualizado con ID:', turnoId);
  } catch (error) {
    console.error('Error al actualizar turno:', error);
    throw error;
  }
};

export const obtenerTurnoPorId = async (cuadranteId, turnoId, currentService) => {
  try {
    // Obtener la referencia al documento del cuadrante y el turno específico
    const cuadranteRef = doc(db, 'servicios', currentService.id, 'cuadrantes', cuadranteId);
    const turnoRef = doc(cuadranteRef, 'turnos', turnoId);

    // Obtener los datos del turno específico
    const turnoDoc = await getDoc(turnoRef);

    if (turnoDoc.exists()) {
      const turnoData = turnoDoc.data();
      // Convertir la fecha y hora de inicio del turno a un timestamp
      const inicioTurnoTimestamp = new Date(`${turnoData.fecha}T${turnoData.inicioTurno}:00`).getTime();
      // Convertir la fecha y hora de fin del turno a un timestamp
      const finTurnoTimestamp = new Date(`${turnoData.fecha}T${turnoData.finTurno}:00`).getTime();

      const turno = {
        id: turnoDoc.id,
        ...turnoData,
        inicioTurnoTimestamp: inicioTurnoTimestamp,
        finTurnoTimestamp: finTurnoTimestamp
      };

      return turno;
    } else {
      throw new Error('El turno especificado no existe.');
    }
  } catch (error) {
    console.error('Error al obtener turno por ID:', error);
    throw error;
  }
};

export const obtenerVigilantesPorServicio = async (servicioId, currentService) => {
  try {
    const vigilantes = [];
    console.log('currentService:', servicioId); // Agrega este registro para verificar el valor de currentService

    // Obtener el servicio de la colección tiendas
    const servicioDoc = await getDoc(doc(db, 'servicios', currentService.id, 'tiendas', servicioId[0]));

    if (servicioDoc.exists()) {
      const servicioData = servicioDoc.data();
      const numTienda = servicioData.numTienda;

      // Realizar una consulta para obtener los vigilantes asignados al servicio
      const vigilantesSnapshot = await getDocs(query(
        collection(db, 'servicios', currentService.id, 'vigilantes')
      ));

      // Iterar sobre los documentos de los vigilantes
      vigilantesSnapshot.forEach((vigilanteDoc) => {
        const vigilanteData = vigilanteDoc.data();

        // Verificar si el vigilante tiene asignada la tienda correspondiente al servicio
        if (vigilanteData.tiendas) {
          vigilanteData.tiendas.forEach((tienda) => {
            // Acceder al número de la tienda dentro del objeto tienda
            if (tienda && tienda.numero === numTienda) {
              // Si el vigilante tiene asignado el servicio, agregarlo a la lista de vigilantes
              vigilantes.push({
                id: vigilanteDoc.id,
                ...vigilanteData
              });
            }
          });
        }
      });
    } else {
      throw new Error('El servicio especificado no existe en la colección tiendas.');
    }
    return vigilantes;
  } catch (error) {
    console.error('Error al obtener vigilantes por servicio:', error);
    throw error;
  }
};

export const obtenerVigilantePorId = async (vigilanteId, currentService) => {
  try {
    const vigilanteRef = doc(db, 'servicios', currentService.id, 'vigilantes', vigilanteId);

    // Obtener los datos del vigilante
    const vigilanteDoc = await getDoc(vigilanteRef);

    if (vigilanteDoc.exists()) {
      // Si el documento del vigilante existe, devolver los datos del vigilante
      return {
        id: vigilanteDoc.id,
        ...vigilanteDoc.data()
      };
    }
    else {
      // Si el documento del vigilante no existe, lanzar un error
      throw new Error('El vigilante especificado no existe.');
    }
  } catch (error) {
    console.error('Error al obtener vigilante por ID:', error);
    throw error;
  }
};


// Función para eliminar una fila (y sus turnos asociados)
export const eliminarFilaCompleta = async (eventIds, currentService, cuadranteId ) => {
  try {
    console.log(eventIds)
    // Obtener la referencia al documento del turno
    const turnoRef = doc(db, 'servicios', currentService.id, 'cuadrantes', cuadranteId, 'turnos', eventIds);

    // Eliminar el turno específico
    await deleteDoc(turnoRef);

  } catch (error) {
    console.error('Error al eliminar fila completa y sus turnos asociados:', error);
    throw error;
  }
};