import './style/styles.css'; // Asegúrate de importar tu archivo CSS


export const userInputs = [
  {
    id: "dni",
    label: "Password (obligatorio)",
    type: "text",
    placeholder: "contraseña*",
  },
  {
    id: "tipVS",
    label: "Tip VS (obligatorio)",
    type: "text",
    placeholder: "Número identificación del vigilante*",
  },
  {
    id: "telefono",
    label: "Telefono",
    type: "text",
    placeholder: "Número de teléfono",
  },
  {
    id: "email",
    label: "email",
    type: "email",
    placeholder: "correo electrónico"
  },
];

export const productInputs = [
  {
    id: "numTienda",
    label: "Identificador servicio",
    type: "text",
    placeholder: "tienda",
  },
  {
    id: "localizacion",
    label: "Localización",
    type: "text",
    placeholder: "localización",
  },
  {
    id: "provincia",
    label: "Provincia",
    type: "dropdown",
    placeholder: "provincia",
  },
  {
    id: "comunidad",
    label: "Comunidad",
    type: "dropdown",
    placeholder: "comunidad",
  },
  {
    id: "inspectoresAsignados",
    label: "Inspectores Asignados",
    type: "text",
    placeholder: "inspectores asignados",
  },
  {
    id: "administrativosAsignados",
    label: "Administrativos Asignados",
    type: "text",
    placeholder: "administrativos asignados",
  },
];

export const clientesInputs = [
  {
    id: "nombre",
    label: "Identificador cliente",
    type: "text",
    placeholder: "nombre",
  },
  {
    id: "telefono",
    label: "Telefono",
    type: "number",
    placeholder: "Número de teléfono",
  },
  {
    id: "email",
    label: "email",
    type: "email",
    placeholder: "correo electrónico"
  },
  {
    id: "provincia",
    label: "Provincia",
    type: "dropdown",
    placeholder: "provincia",
  },
  {
    id: "comunidad",
    label: "Comunidad",
    type: "dropdown",
    placeholder: "comunidad",
  },
  {
    id: "inspectoresAsignados",
    label: "Inspectores Asignados",
    type: "dropdown",
    placeholder: "inspectores asignados",
  },
  {
    id: "administrativosAsignados",
    label: "Administrativos Asignados",
    type: "dropdown",
    placeholder: "administrativos asignados",
  },
];
