import "./datatable.scss";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { userColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  where,
  query,
  getDoc
} from "firebase/firestore";
import { db } from "../../firebase";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import customLocaleText from './LocaleText.js'
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Componente para mostrar una tabla de datos.
 */
const Datatable = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [showDeleted, setShowDeleted] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]); // Nuevo estado para almacenar las filas seleccionadas

  const { currentUser, currentService, adminData } = useContext(AuthContext);

  /**
 * Función asíncrona para cargar datos desde Firebase.
 */
  const fetchData = async () => {
    if (!currentService.id || !adminData || !adminData.tiendas) {
      console.error("Información de servicio o administrador no disponible.");
      return;
    }


    try {
      const adminTiendasNumeros = await Promise.all(
        adminData.tiendas.map(async (tiendaId) => {
          const tiendaRef = doc(db, "servicios", currentService.id, "tiendas", tiendaId);
          const tiendaDoc = await getDoc(tiendaRef);
          return tiendaDoc && tiendaDoc.exists() && tiendaDoc.data() ? tiendaDoc.data().numTienda : null;
        })
      );

      console.log("Admin Tiendas Números:", adminTiendasNumeros);

      // Obtener información de vigilantes
      const vigilantesRef = collection(db, "servicios", currentService.id, "vigilantes");
      const vigilantesSnapshot = await getDocs(vigilantesRef);
      const vigilantesList = vigilantesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Obtener información de clientes basada en las tiendas de los vigilantes
      const clientesList = await Promise.all(vigilantesList.map(async (vigilante) => {
        const clientes = [];

        if (vigilante.tiendas) {
          for (const tienda of vigilante.tiendas) {
            const tiendaNumero = tienda.numero;

            // Realizar una consulta para obtener la tienda basada en el número
            const tiendasQuery = query(
              collection(db, "servicios", currentService.id, "tiendas"),
              where("numTienda", "==", tiendaNumero || "")  // Asegúrate de que tiendaNumero no sea undefined
            );


            const tiendasSnapshot = await getDocs(tiendasQuery);

            tiendasSnapshot.forEach((tiendaDoc) => {
              if (tiendaDoc.exists()) {
                const tiendaData = tiendaDoc.data();

                clientes.push({
                  id: `${vigilante.id}-${tiendaDoc.id}`,
                  cliente: tiendaData.nombreCliente || [],
                });
              } else {
                console.log(`Tienda Número ${tiendaNumero} no encontrada.`);
              }
            });
          }
        }

        return clientes;
      }));

      // Flatten the array of arrays into a single array
      const flattenedClientesList = clientesList.flat();

      // Combinar la información de vigilantes y clientes
      const combinedData = vigilantesList.map((vigilante) => {
        const uniqueClientes = Array.from(new Set(flattenedClientesList
          .filter((cliente) => cliente.id.startsWith(`${vigilante.id}-`))
          .flatMap((cliente) => cliente.cliente)));
        return { ...vigilante, clientes: uniqueClientes };
      });

      setData(combinedData);
    } catch (err) {
      console.error("Error al cargar los datos:", err);
    }
  };

  console.log("vigilantes ", data)

  useEffect(() => {
    fetchData();
  }, [currentUser, currentService, adminData]);


  useEffect(() => {
    const updateFilteredData = () => {
      /**
     * Función para actualizar los datos filtrados.
     */
      const updatedFilteredData = data
        .filter((row) => {
          const rowValues = Object.values(row).join('').toLowerCase();
          return rowValues.includes(filter.toLowerCase());
        })
        .filter((row) => {
          if (showDeleted) {
            return row.borrado; // Mostrar solo los registros borrados si showDeleted es true
          } else {
            return !row.borrado; // Mostrar solo los registros no borrados si showDeleted es false
          }
        })
        .map((row) => {
          if (Array.isArray(row.tiendas)) {
            return { ...row, tiendas: row.tiendas.map((tienda) => `${tienda.numero}`) };
          } else {
            return row;
          }
        });
      setFilteredData(updatedFilteredData);
    };

    updateFilteredData();
  }, [data, filter, showDeleted]);


  /**
 * Maneja el evento de eliminación de un vigilante.
 * @param {string} id - ID del vigilante a eliminar.
 */
  const handleDelete = async (id) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar los vigilantes seleccionados?")) {
      console.log("ids to restore: ", id)

      try {
        if (Array.isArray(id)) {
          for (const idRow of id) {
            const vigilanteRef = doc(db, "servicios", currentService.id, "vigilantes", idRow);
            await updateDoc(vigilanteRef, { borrado: true });
          }
        } else {
          const vigilanteRef = doc(db, "servicios", currentService.id, "vigilantes", id);
          await updateDoc(vigilanteRef, { borrado: true });
        }
        // Recargar los datos después de la eliminación
        await fetchData();
      } catch (err) {
        console.log(err);
      }
    }
  };

  /**
 * Maneja el cambio en la casilla de verificación "Mostrar borrados".
 * @param {object} event - Evento de cambio.
 */
  const handleShowDeletedChange = (event) => {
    setShowDeleted(event.target.checked);
  };

  // Asegúrate de que onSelectionModelChange esté actualizando correctamente selectedRows
  const onSelectionModelChange = (newSelectionModel) => {
    console.log('Selección actualizada:', newSelectionModel); // Agrega esto para depuración
    setSelectedRows(newSelectionModel);
  };

  /**
 * Maneja el evento de restauración de un vigilante.
 * @param {string} id - ID del vigilante a restaurar.
 */
  const handleRestore = async (id) => {
    const confirmRestore = window.confirm("¿Estás seguro de que deseas recuperar este vigilante?");
    console.log("ids to restore: ", id)

    if (confirmRestore) {
      try {
        if (Array.isArray(id)) {
          for (const idRow of id) {
            const vigilanteRef = doc(db, "servicios", currentService.id, "vigilantes", idRow);
            await updateDoc(vigilanteRef, { borrado: false });
          }
        } else {
          const vigilanteRef = doc(db, "servicios", currentService.id, "vigilantes", id);
          await updateDoc(vigilanteRef, { borrado: false });
        }
        // Recargar los datos después de la restauración
        await fetchData();
      } catch (err) {
        console.log(err);
      }
    }
  };


  const actionColumn = {
    field: "action",
    headerName: "Action",
    width: 250,
    renderCell: (params) => {
      return (
        <div className="cellAction">
          <Link
            to={`/vigilantes/${encodeURIComponent(params.row.tipVS)}`}
            style={{ textDecoration: "none" }}
          >
            <div className="viewButton">Ver</div>
          </Link>
          <Link to={`/vigilantes/update/${encodeURIComponent(params.row.tipVS)}`}
            style={{ textDecoration: "none" }}>
            <EditIcon className="editIcon" /> {/* Ícono de lápiz para editar */}
          </Link>
          {params.row.borrado ? (
            <div
              className="viewButton"
              onClick={() => handleRestore(Array.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows : params.row.id)}
            >
              Recuperar
            </div>
          ) : null}
          {!params.row.borrado ? (
            <DeleteIcon
              className="deleteIcon"
              onClick={() => handleDelete(Array.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows : params.row.id)}
            />
          ) : null}
        </div>
      );
    },
  };


  return (
    <div className="datatable">
      <div className="datatableTitle">
        <h2 className="title-registro">Registro Vigilantes</h2>
        <div className="rightActions">
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleted}
                onChange={handleShowDeletedChange}
                color="primary"
              />
            }
            label="Mostrar borrados"
          />
          <Link to="/vigilantes/new" className="link">
            Nuevo perfil
          </Link>
        </div>
      </div>
      <DataGridPro
        className="datagrid"
        rows={filteredData}
        columns={userColumns.concat(actionColumn)}
        pageSize={100}
        rowsPerPageOptions={[9]}
        checkboxSelection
        localeText={customLocaleText}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        slots={{ toolbar: GridToolbar }}
        onRowSelectionModelChange={onSelectionModelChange}
      />
    </div>
  );
};

export default Datatable;