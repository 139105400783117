import React, { useState, useEffect } from 'react';
import { Button, FormControlLabel, Checkbox } from '@mui/material';
import { agregarTurno, obtenerVigilantesPorServicio } from './FirestoreCuadrantes';

const CreateTramo = ({ onClose, cuadranteId, servicioId, currentService }) => {
  const [inicioTurno, setInicioTurno] = useState('');
  const [finTurno, setFinTurno] = useState('');
  const [vigilantes, setVigilantes] = useState([]);
  const [vigilanteSeleccionado, setVigilanteSeleccionado] = useState('');
  const [mesSeleccionado, setMesSeleccionado] = useState('');
  const [arcos, setArcos] = useState(false);
  const [manejoExplosivos, setManejoExplosivos] = useState(false);
  const [peligrosidad, setPeligrosidad] = useState(false);
  const [conArma, setConArma] = useState(false);
  const [observaciones, setObservaciones] = useState('');

  useEffect(() => {
    const fetchVigilantes = async () => {
      try {
        if (currentService) {
          const vigilantesData = await obtenerVigilantesPorServicio(servicioId, currentService);
          setVigilantes(vigilantesData);
        }
      } catch (error) {
        console.error('Error al obtener vigilantes:', error);
      }
    };

    fetchVigilantes();
  }, [currentService, cuadranteId]);

  const handleInicioTurnoChange = (event) => {
    setInicioTurno(event.target.value);
  };

  const handleFinTurnoChange = (event) => {
    setFinTurno(event.target.value);
  };

  const handleVigilanteChange = (event) => {
    setVigilanteSeleccionado(event.target.value);
  };

  const handleMesChange = (event) => {
    setMesSeleccionado(event.target.value);
  };

  const handleArcosChange = (event) => {
    setArcos(event.target.checked);
  };

  const handleManejoExplosivosChange = (event) => {
    setManejoExplosivos(event.target.checked);
  };

  const handlePeligrosidadChange = (event) => {
    setPeligrosidad(event.target.checked);
  };

  const handleConArmaChange = (event) => {
    setConArma(event.target.checked);
  };

  const handleObservacionesChange = (event) => {
    setObservaciones(event.target.value);
  };

  const handleSubmitTramo = async (event) => {
    event.preventDefault();

    try {
      const [year, month] = mesSeleccionado.split('-');
      const firstDayOfMonth = new Date(year, month - 1, 1);
      const lastDayOfMonth = new Date(year, month, 0);
      const fechas = [];

      for (let day = firstDayOfMonth; day <= lastDayOfMonth; day.setDate(day.getDate() + 1)) {
        const formattedDate = day.toISOString().split('T')[0];
        fechas.push(formattedDate);
      }

      await Promise.all(
        fechas.map((fecha) =>
          agregarTurno(cuadranteId, {
            inicioTurno,
            finTurno,
            fecha,
            vigilante: vigilanteSeleccionado,
            arcos,
            manejoExplosivos,
            peligrosidad,
            conArma,
            observaciones
          }, currentService)
        )
      );

      onClose(); // Cierra el formulario después de agregar los tramos
    } catch (error) {
      console.error('Error al agregar tramos:', error);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Crear Tramo</h2>
        <form onSubmit={handleSubmitTramo}>
          <label>
            Mes y Año:
            <input
              type="month"
              value={mesSeleccionado}
              onChange={handleMesChange}
            />
          </label>
          <label>
            Hora de inicio:
            <input
              type="time"
              value={inicioTurno}
              onChange={handleInicioTurnoChange}
            />
          </label>
          <label>
            Hora de fin:
            <input
              type="time"
              value={finTurno}
              onChange={handleFinTurnoChange}
            />
          </label>
          <select
            id="vigilante"
            value={vigilanteSeleccionado}
            onChange={handleVigilanteChange}
          >
            <option value="">Seleccionar vigilante</option>
            {vigilantes.map((vigilante) => (
              <option key={vigilante.id} value={vigilante.id}>
                {vigilante.nombre}
              </option>
            ))}
          </select>
          <FormControlLabel
            control={<Checkbox checked={arcos} onChange={handleArcosChange} />}
            label="Arcos"
          />
          <FormControlLabel
            control={<Checkbox checked={manejoExplosivos} onChange={handleManejoExplosivosChange} />}
            label="Manejo de explosivos"
          />
          <FormControlLabel
            control={<Checkbox checked={peligrosidad} onChange={handlePeligrosidadChange} />}
            label="Peligrosidad"
          />
          <FormControlLabel
            control={<Checkbox checked={conArma} onChange={handleConArmaChange} />}
            label="Con arma"
          />
          <label>
            Observaciones:
            <input
              type="text"
              value={observaciones}
              onChange={handleObservacionesChange}
            />
          </label>
          <Button type="submit">Crear Tramo</Button>
          <Button onClick={onClose}>Cancelar</Button>
        </form>
      </div>
    </div>
  );
};

export default CreateTramo;
