import React, { useState, useEffect } from 'react';
import CreateTramo from './CreateTramo';
import { eliminarFilaCompleta } from './FirestoreCuadrantes';
import { Button } from '@mui/material';
import ActualizarTurno from './Actualizarturno'; // Importar el componente ActualizarTurno
import './GridView.css';

const GridView = ({ events, cuadranteId, currentService, servicioId }) => {
  const [mostrarFormularioTramo, setMostrarFormularioTramo] = useState(false);
  const [filaAEliminar, setFilaAEliminar] = useState(null);
  const [mesSeleccionado, setMesSeleccionado] = useState(new Date());
  const [eventosCuadricula, setEventosCuadricula] = useState([]);
  const [turnoSeleccionado, setTurnoSeleccionado] = useState(null); // Estado para almacenar el ID del turno seleccionado

  const handleMostrarFormularioTramo = () => {
    setMostrarFormularioTramo(true);
  };

  const handleEliminarFila = (filaIndex) => {
    const vigilanteFilaSeleccionada = uniqueVigilantes[filaIndex];
    const eventosFilaSeleccionada = events.filter((event) => event.title === vigilanteFilaSeleccionada);
    const eventIds = eventosFilaSeleccionada.map((event) => event.id);
    console.log(eventIds);
    setFilaAEliminar(eventIds);
  };

  const handleConfirmarEliminarFila = () => {
    console.log('Fila seleccionada para eliminar:', filaAEliminar);
    filaAEliminar.forEach((eventId) => {
      eliminarFilaCompleta(eventId, currentService, cuadranteId);
    });

    setEventosCuadricula((prevEventos) =>
      prevEventos.filter((evento) => !filaAEliminar.includes(evento.id))
    );
    setFilaAEliminar(null);
  };

  const obtenerHoraInicioFin = (vigilante, dia) => {
    const eventosVigilanteEnDia = events.filter(
      (event) =>
        event.title === vigilante &&
        new Date(event.start).getDate() === dia &&
        new Date(event.start).getMonth() === mesSeleccionado.getMonth()
    );

    if (eventosVigilanteEnDia.length === 0) return null;

    const primerEvento = eventosVigilanteEnDia[0];
    const ultimaEvento = eventosVigilanteEnDia[eventosVigilanteEnDia.length - 1];

    const horaInicio = new Date(primerEvento.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const horaFin = new Date(ultimaEvento.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return `${horaInicio} - ${horaFin}`;
  };

  const daysOfMonth = [];
  const today = new Date();
  for (let i = 1; i <= 30; i++) {
    const date = new Date(today.getFullYear(), today.getMonth(), i);
    daysOfMonth.push(date.getDate() + " " + date.toLocaleString('default', { month: 'short' }));
  }

  const calcularTotalHoras = (vigilante) => {
    const eventosVigilante = events.filter((event) => event.title === vigilante && new Date(event.start).getMonth() === mesSeleccionado.getMonth());
    let totalHoras = 0;
    eventosVigilante.forEach((event) => {
      const inicio = new Date(event.start);
      const fin = new Date(event.end);
      const diff = Math.abs(fin - inicio);
      totalHoras += diff / (1000 * 60 * 60);
    });
    return totalHoras.toFixed(2);
  };

  const uniqueVigilantes = [...new Set(events.map((event) => event.title))];

  const handleEditarCasilla = (vigilante, dia) => {
    // Aquí puedes implementar la lógica para cargar el turno correspondiente al día y permitir su edición
    console.log(`Editando casilla para ${vigilante} el día ${dia}`);

    // Encuentra el evento correspondiente al día y al vigilante
    const evento = events.find(event => event.title === vigilante && new Date(event.start).getDate() === dia);

    // Si hay un evento para este día y vigilante, establece el turno seleccionado con el ID del evento
    if (evento) {
      setTurnoSeleccionado(evento.id);
    } else {
      setTurnoSeleccionado(null);
    }
  };

  const handleCloseModal = () => {
    setTurnoSeleccionado(null);
  };

  return (
    <div className="grid-view">
      <div className="select-month">
        <label htmlFor="mesSeleccionado">Seleccione el mes:</label>
        <select id="mesSeleccionado" value={mesSeleccionado} onChange={(e) => setMesSeleccionado(new Date(e.target.value))}>
          <option value="">Mes actual</option>
          <option value="2024-01-01">Enero</option>
          <option value="2024-02-01">Febrero</option>
          <option value="2024-03-01">Marzo</option>
          <option value="2024-04-01">Abril</option>
          <option value="2024-05-01">Mayo</option>
          <option value="2024-06-01">Junio</option>
          <option value="2024-07-01">Julio</option>
          <option value="2024-08-01">Agosto</option>
          <option value="2024-09-01">Septiembre</option>
          <option value="2024-10-01">Octubre</option>
          <option value="2024-11-01">Noviembre</option>
          <option value="2024-12-01">Diciembre</option>
        </select>
      </div>
      <table>
        <thead>
          <tr>
            <th>Vigilantes</th>
            {daysOfMonth.map((day, index) => (
              <th key={index}>{day}</th>
            ))}
            <th>Total Horas</th>
          </tr>
        </thead>
        <tbody>
          {uniqueVigilantes.map((vigilante, vIndex) => (
            <tr key={vIndex}>
              <td>{vigilante}</td>
              {daysOfMonth.map((day, dIndex) => (
                <td key={`${vIndex}-${dIndex}`} onClick={() => handleEditarCasilla(vigilante, dIndex + 1)}>
                  {obtenerHoraInicioFin(vigilante, dIndex + 1)}
                </td>
              ))}
              <td>{calcularTotalHoras(vigilante)}</td>
              <td></td>
              <td>
                <button onClick={() => handleEliminarFila(vIndex)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {turnoSeleccionado && ( // Si hay un turno seleccionado, muestra el componente ActualizarTurno
        <ActualizarTurno
          cuadranteId={cuadranteId}
          turnoId={turnoSeleccionado}
          currentService={currentService}
          onUpdateComplete={() => setTurnoSeleccionado(null)} // Limpiar el turno seleccionado después de la actualización
          servicioId={servicioId}
          onClose={handleCloseModal}
        />
      )}
       {servicioId && ( // Mostrar el botón de "Crear Tramo" solo si servicioId está definido
        <button onClick={handleMostrarFormularioTramo}>Crear Tramo</button>
      )}
      {mostrarFormularioTramo && <CreateTramo onClose={() => setMostrarFormularioTramo(false)} cuadranteId={cuadranteId} currentService={currentService} servicioId={servicioId} />}
      {filaAEliminar !== null && (
        <div>
          <p>¿Estás seguro de que deseas eliminar esta fila?</p>
          <button onClick={handleConfirmarEliminarFila}>Confirmar</button>
          <button onClick={() => setFilaAEliminar(null)}>Cancelar</button>
        </div>
      )}
    </div>
  );
};

export default GridView;
