import "./DataCuadrantes.scss";
import { useEffect, useState, useContext } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Button } from '@mui/material';
import { AuthContext } from "../../context/AuthContext";
import { fetchClientesPorAdministrativo, fetchClientesPorInspector, fetchUserData, fetchTiendasPorCampoCliente, } from "../../components/admin/firestoreService"
import { fetchTiendas, registerVigilante } from "../../pages/new/utils/FirebaseUtils"
import { createCuadrante, agregarTurno, obtenerTurnosPorCuadrante, obtenerVigilantePorId } from "./FirestoreCuadrantes";
import CreateTurno from './CreateTurno';
import ActualizarTurno from "./Actualizarturno";
import GridView from "./GridView";

const DataCuadrantes = () => {
  const [clientes, setClientes] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [selectedClientes, setSelectedClientes] = useState([]);
  const [selectedServicios, setSelectedServicios] = useState([]);
  const [userData, setUserData] = useState({});
  const [clienteSeleccionado, setClienteSeleccionado] = useState('');
  const [isAllClientesSelected, setIsAllClientesSelected] = useState(false);
  const [isAllTiendasSelected, setIsAllTiendasSelected] = useState(false);
  const [serviciosFiltrados, setServiciosFiltrados] = useState([]);
  const [events, setEvents] = useState([]);
  const [mostrarFormularioTurno, setMostrarFormularioTurno] = useState(false);
  const [cuadranteId, setCuadranteId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [turnoId, setTurnoId] = useState('');
  const [eventoTurnoSeleccionado, setEventoTurnoSeleccionado] = useState(null);
  const [isGridView, setIsGridView] = useState(false);

  const { currentService, currentUser, adminData } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentUser && currentService) {
          fetchUserData(currentUser, currentService)
            .then(setUserData)
            .catch(console.error);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [currentUser, currentService]); // Dependencias: currentUser y currentService

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        if (userData) {
          const clientesData = await fetchClientesPorInspector(userData.id, currentService);
          setClientes(clientesData);
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchClientData();
  }, [userData, currentService]); // Dependencias: userData y currentService

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        if (currentUser) {
          const serviciosData = await fetchTiendas(currentUser.email);
          setServicios(serviciosData);
          console.log("servicios data", serviciosData);
        }
      } catch (error) {
        console.error("Error fetching service data:", error);
      }
    };

    fetchServiceData();
  }, [currentUser]); // Dependencia: currentUser

  useEffect(() => {
    if (clienteSeleccionado && currentService) {
      fetchTiendasPorCampoCliente(clienteSeleccionado, currentService)
        .then(setServicios) // Actualiza el estado con las tiendas filtradas
        .catch(console.error);
    }
  }, [clienteSeleccionado, currentService]);

  useEffect(() => {
    const fetchTurnosCuadrante = async () => {
      try {
        if (cuadranteId) {
          const turnos = await obtenerTurnosPorCuadrante(cuadranteId, currentService);

          const eventos = [];

          // Iterar sobre los turnos para crear eventos compatibles con FullCalendar
          for (const turno of turnos) {
            // Verificar si el turno tiene un vigilante asignado
            if (turno.vigilante) {
              // Si el turno tiene un vigilante asignado, obtener los datos del vigilante
              const vigilante = await obtenerVigilantePorId(turno.vigilante, currentService);

              // Verificar si se encontraron datos del vigilante
              if (vigilante) {
                // Si se encontraron datos del vigilante, crear el evento con el nombre del vigilante
                eventos.push({
                  id: turno.id,
                  title: vigilante.nombre + " " + vigilante.apellidos, // Utiliza el nombre del vigilante como título del evento
                  start: turno.inicioTurnoTimestamp,
                  end: turno.finTurnoTimestamp,
                  // Otros campos del evento
                });
              } else {
                // Si no se encontraron datos del vigilante, mostrar un mensaje de error o manejar la situación según sea necesario
                console.error('No se encontraron datos del vigilante para el turno:', turno.id);
              }
            } else {
              // Si el turno no tiene un vigilante asignado, mostrar un mensaje de advertencia o manejar la situación según sea necesario
              console.warn('El turno no tiene un vigilante asignado:', turno.id);
            }
          }

          // Actualizar el estado de los eventos con los eventos del cuadrante seleccionado
          setEvents(eventos);
        }
      } catch (error) {
        console.error('Error al obtener los turnos del cuadrante:', error);
      }
    };


    fetchTurnosCuadrante();
  }, [cuadranteId]); // Dependencia: cuadranteId

  const handleClienteChange = (event) => {
    setSelectedClientes(event.target.value);

    // Filtrar y establecer las tiendas basadas en los clientes seleccionados
    const nuevasTiendasFiltradas = servicios.filter(servicio =>
      event.target.value.some(clienteId => servicio?.cliente === clienteId)
    );

    setServiciosFiltrados(nuevasTiendasFiltradas);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = async () => {
    // Vuelve a obtener los turnos del cuadrante actualizado
    const turnos = await obtenerTurnosPorCuadrante(cuadranteId, currentService);

    // Transforma los turnos en eventos compatibles con FullCalendar
    const eventos = turnos.map((turno) => ({
      id: turno.id,
      vigilantes: turno.vigilante,
      start: turno.inicioTurnoTimestamp,
      end: turno.finTurnoTimestamp,
    }));

    // Actualiza el estado de los eventos con los eventos actualizados
    setEvents(eventos);
    setModalOpen(false);
    handleUpdateTurnoComplete()
  };


  const handleServiciosChange = (event) => {
    const { value } = event.target;
    if (value.includes('all')) {
      setSelectedServicios(isAllTiendasSelected ? [] : servicios.map(servicio => servicio.id));
    } else {
      setIsAllTiendasSelected(value.length === servicios.length);
      setSelectedServicios(event.target.value)
    }
  };

  const handleSubmit = async (event) => {
    console.log("servicio actual", currentService.id)
    event.preventDefault();

    try {
      // Aquí puedes manejar la lógica para crear cuadrantes con los clientes y servicios seleccionados
      const cuadranteId = await createCuadrante(selectedClientes, currentService, selectedServicios, currentUser.uid);
      setCuadranteId(cuadranteId);
      setMostrarFormularioTurno(true); // Mostrar el formulario de creación de turno

      // Obtener los turnos del cuadrante creado
      const turnos = await obtenerTurnosPorCuadrante(cuadranteId, currentService);

      // Mapear los turnos para obtener los eventos
      const eventosPromises = turnos.map(async (turno) => {
        // Verificar si el turno tiene un vigilante asignado
        if (turno.vigilante) {
          // Obtener los datos del vigilante utilizando su ID
          const vigilanteData = await obtenerVigilantePorId(turno.vigilante, currentService);

          // Crear el evento con los datos del turno y del vigilante
          return {
            id: turno.id,
            title: vigilanteData.nombre + " " + vigilanteData.apellidos, // Suponiendo que el nombre del vigilante está en el campo 'nombre'
            start: turno.inicioTurnoTimestamp,
            end: turno.finTurnoTimestamp,
            // Otros campos del turno que quieras mostrar en el calendario
          };
        } else {
          // Si el turno no tiene un vigilante asignado, crear un evento sin título
          return {
            id: turno.id,
            title: 'Turno sin vigilante asignado', // Otra forma de manejar los turnos sin vigilante
            start: turno.inicioTurnoTimestamp,
            end: turno.finTurnoTimestamp,
            // Otros campos del turno que quieras mostrar en el calendario
          };
        }
      });

      // Esperar a que se resuelvan todas las promesas de eventos
      const eventos = await Promise.all(eventosPromises);

      // Actualizar el estado de los eventos con los eventos del cuadrante seleccionado
      setEvents(eventos);
    } catch (error) {
      console.error('Error al manejar el envío del formulario:', error);
    }
  };


  const handleEventClick = (info) => {
    const turnoId = info.event.id;
    console.log('Evento clicado:', info.event.id);
    setEventoTurnoSeleccionado(turnoId); // Establece el ID del turno seleccionado
    handleOpenModal(); // Abre el modal al hacer clic en el evento
  };

  const handleUpdateTurnoComplete = () => {
    // Limpiar la selección de turno
    setEventoTurnoSeleccionado(null);
  };


  const toggleView = () => {
    setIsGridView(!isGridView); // Alternar entre la vista de calendario y la vista de cuadrícula
  };

  return (
    <div className="cuadrantes">
      <div className="cuadrantesContainer">
        <div className="cuadrantesContent">
          <h1>Crear Cuadrantes</h1>
          <form onSubmit={handleSubmit}>
            <FormControl className="formInput">
              <InputLabel id="clientes-label">Clientes</InputLabel>
              <Select
                labelId="clientes-label"
                id="clientes"
                multiple
                value={selectedClientes}
                onChange={handleClienteChange}
                label="Clientes"
              >
                {clientes.map((cliente) => (
                  <MenuItem key={cliente.id} value={cliente.id}>
                    {cliente.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="formInput">
              <InputLabel id="servicios-label">Servicios</InputLabel>
              <Select
                labelId="servicios-label"
                id="servicios"
                multiple
                value={selectedServicios}
                onChange={handleServiciosChange}
                label="Servicios"
              >
                <MenuItem value="all">
                  <Checkbox checked={isAllTiendasSelected} />
                  <ListItemText primary="Seleccionar todos los servicios" />
                </MenuItem>
                {serviciosFiltrados.map((servicio) => (
                  <MenuItem key={servicio.id} value={servicio.id}>
                    {servicio.numTienda}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button type="submit" variant="contained" color="primary">Cuadrante</Button>
            <Button onClick={toggleView} variant="contained" color="primary">
              {isGridView ? "Ver Calendario" : "Ver Vista de Cuadrícula"}
            </Button>
            <Button type="submit" variant="contained" color="primary">Actualizar</Button>
            {cuadranteId && (
              <div>
                <h2>Cuadrante Cargado</h2>
                {/* <p>Cuadrante ID: {cuadranteId}</p> */}
                {/* Agrega más detalles del cuadrante si es necesario */}
              </div>
            )}
          </form>
          {isGridView ? (
            <div className="gridView">
              <GridView events={events} cuadranteId={cuadranteId} currentService={currentService} servicioId={selectedServicios} />
            </div>
          ) : (
            <div className="calendar">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                displayEventEnd={true}
                initialView="dayGridMonth"
                // editable={true}
                eventClick={handleEventClick}
                events={events}
              />
              <Button onClick={handleOpenModal} variant="contained" color="primary">Crear turno</Button>
              {modalOpen && (
                <div className="modal">
                  <div className="modal-content">
                    <Button className="modal-close" onClick={handleCloseModal}>Cerrar </Button>
                    {mostrarFormularioTurno && <CreateTurno cuadranteId={cuadranteId} currentService={currentService} servicioId={selectedServicios} />}
                  </div>
                </div>
              )}
              {modalOpen && (
                <div className="modal">
                  <div className="modal-content">
                    <Button className="modal-close" onClick={handleCloseModal}>Cerrar</Button>
                    {mostrarFormularioTurno && !eventoTurnoSeleccionado && (
                      <CreateTurno cuadranteId={cuadranteId} currentService={currentService} servicioId={selectedServicios} />
                    )}
                    {mostrarFormularioTurno && eventoTurnoSeleccionado && (
                      <ActualizarTurno cuadranteId={cuadranteId} turnoId={eventoTurnoSeleccionado} 
                      currentService={currentService} onUpdateComplete={handleUpdateTurnoComplete} 
                      servicioId={selectedServicios} onClose={handleCloseModal}/>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataCuadrantes;