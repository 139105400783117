import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState, useContext } from "react";
import {
  addDoc,
  collection,
  serverTimestamp,
  getDocs,
  where,
  query,
  updateDoc,
  arrayUnion,
  doc
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { uploadLogToFirestore, uploadErrorLogToFirestore } from "../../utils/logUtils";
import { fetchAdministrativosAsignados, fetchInspectoresAsignados } from "./utils/FirebaseUtils";
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";
import { comunidades, provincias } from "../../zoneData";
import noImage from '../../images/no-image.png'
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';


/**
 * Componente para crear una nueva cliente.
 *
 * @param {Object} inputs - Los campos de entrada para la cliente.
 * @param {string} title - El título del formulario.
 * @returns {JSX.Element} - Componente Newcliente.
 */
const NewCliente = ({ inputs, title }) => {
  const { currentUser, currentService, adminData } = useContext(AuthContext);


  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [inspectoresAsignados, setInspectoresAsignados] = useState([]);
  const [administrativosAsignados, setAdministrativosAsignados] = useState([]);
  const [selectedInspectores, setSelectedInspectores] = useState([]);
  const [selectedAdministrativos, setSelectedAdministrativos] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [adminProvincias, setAdminProvincias] = useState(adminData?.provincias || []);
  const [adminComunidades, setAdminComunidades] = useState(adminData?.comunidades || []);


  const navigate = useNavigate();
  const { collapsed } = useSidebarState();
  const containerClass = collapsed ? "newContainer collapsed" : "newContainer";

  useEffect(() => {
    // Comprobar si currentService está disponible
    if (currentService) {
      const currentServiceId = currentService.id;

      // Obtener inspectores asignados
      fetchInspectoresAsignados(currentServiceId).then((inspectores) => {
        setInspectoresAsignados(inspectores);
      });

      // Obtener administrativos asignados
      fetchAdministrativosAsignados(currentServiceId).then((administrativos) => {
        setAdministrativosAsignados(administrativos);
      });
    }
  }, [currentService]);

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          uploadErrorLogToFirestore(error)

        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  /**
 * Maneja el cambio de los campos de entrada.
 *
 * @param {Object} e - Evento del campo de entrada.
 */
  const handleInput = (e) => {
    const id = e.target.id || e.target.name; // Usar name como fallback si id no está disponible
    const isSelectMultiple = Array.isArray(e.target.value); // Verificar si es un Select múltiple
    const value = isSelectMultiple ? e.target.value : e.target.value;


    // Actualizar el estado según el ID del campo
    if (id === "inspectoresAsignados") {
      setSelectedInspectores(value);
    } else if (id === "administrativosAsignados") {
      setSelectedAdministrativos(value);
    } else {
      setData({ ...data, [id]: value });
    }
  };

  /**
 * Maneja el registro de la nueva cliente.
 *
 * @param {Object} e - Evento del formulario.
 */
  const handleAdd = async (e) => {
    e.preventDefault();

    try {
      const serviciosRef = collection(db, "servicios");
      const q = query(
        serviciosRef,
        where("admins", "array-contains", currentUser.email)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const servicioDoc = querySnapshot.docs[0];
        const clientesRef = collection(servicioDoc.ref, "clientes");

        // Check if the client with the same name already exists
        const existingClients = await getDocs(
          query(clientesRef, where("nombre", "==", data.nombre))
        );

        if (!existingClients.empty) {
          alert(
            "El cliente con este nombre ya ha sido registrado. Por favor, elige otro nombre."
          );

          console.log("Client with the same name already exists!");
          uploadErrorLogToFirestore("Client with the same name already exists!");
          return;
        }

        // If the client with the same name doesn't exist, proceed with adding the new client
        const newClientRef = await addDoc(clientesRef, {
          ...data,
          timeStamp: serverTimestamp(),
          inspectoresAsignados: selectedInspectores, // Agrega inspectores asignados
          administrativosAsignados: selectedAdministrativos, // Agrega administrativos asignados
        });

        // Get the ID assigned to the new client document
        const newClientId = newClientRef.id;

        // Update the document with the assigned ID
        await updateDoc(newClientRef, { id: newClientId });

        // Update admins collection for each assigned inspector
        for (const inspectorId of selectedInspectores) {
          const inspectorRef = doc(db, 'servicios', currentService.id, 'admins', inspectorId);
          await updateDoc(inspectorRef, {
            clientes: arrayUnion(newClientId),
          });
        }

        // Update admins collection for each assigned administrativo
        for (const administrativoId of selectedAdministrativos) {
          const administrativoRef = doc(db, 'servicios', currentService.id, 'admins', administrativoId);
          await updateDoc(administrativoRef, {
            clientes: arrayUnion(newClientId),
          });
        }

        uploadLogToFirestore("cliente añadido");

        navigate("/clientes");

        // Forzar la recarga de la página
        window.location.reload();
        navigate("/clientes");

      }
    } catch (err) {
      console.log(err);
    }
  };

  const getInspectorNameById = (id) => {
    const inspector = inspectoresAsignados.find(ins => ins.id === id);
    return inspector ? inspector.apellido ? `${inspector.nombre} ${inspector.apellido}` : inspector.nombre : '';
  };

  const getAdministrativoNameById = (id) => {
    const administrativo = administrativosAsignados.find(adm => adm.id === id);
    return administrativo ? administrativo.apellido ? `${administrativo.nombre} ${administrativo.apellido}` : administrativo.nombre : '';
  };

  return (
    <div className="new">
      <Sidebar />
      <div className={containerClass}>
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate(-1);
            }}
            style={{ marginLeft: '20px' }}
          >
            Volver
          </Button>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : noImage
              }
              alt=""
            />
            <label htmlFor="file">
              <div className='upload-label'>
                <DriveFolderUploadOutlinedIcon className="icon" /> Subir imagen
              </div>
            </label>
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              <div className="inputPairs">
                {inputs.map((input) => (
                  <div className="formInput" key={input.id}>
                    <label>{input.label}</label>
                    {input.id === "inspectoresAsignados" && (
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="inspectoresAsignados-label">{input.label}</InputLabel>
                        <Select
                          id={input.id}
                          name="inspectoresAsignados"
                          multiple
                          value={selectedInspectores}
                          onChange={(e) => handleInput(e)}
                          input={<OutlinedInput id="select-inspectores" label="Inspectores" />}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((id) => (
                                <Chip key={id} label={getInspectorNameById(id)} />
                              ))}
                            </Box>
                          )}
                        >
                          {inspectoresAsignados.map((inspector) => (
                            <MenuItem key={inspector.id} value={inspector.id}>
                              {inspector.nombre} {inspector.apellido}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {input.id === "administrativosAsignados" && (
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="administrativosAsignados-label">{input.label}</InputLabel>
                        <Select
                          id={input.id}
                          name="administrativosAsignados"
                          multiple
                          value={selectedAdministrativos}
                          onChange={(e) => handleInput(e)}
                          input={<OutlinedInput id="select-administrativos" label="Administrativos" />}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((id) => (
                                <Chip key={id} label={getAdministrativoNameById(id)} />
                              ))}
                            </Box>
                          )}
                        >
                          {administrativosAsignados.map((administrativo) => (
                            <MenuItem key={administrativo.id} value={administrativo.id}>
                              {administrativo.nombre} {administrativo.apellido}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {(input.type === "dropdown" && input.id !== "administrativosAsignados" && input.id !== "inspectoresAsignados") && ( // Verifica si el tipo es "dropdown"
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id={`${input.id}-label`}>{input.label}</InputLabel>
                        <Select
                          id={input.id}
                          name={input.id}
                          value={selectedValues[input.id] || ""}
                          onChange={(e) => handleInput(e)}
                          input={<OutlinedInput id={`select-${input.id}`} label={input.label} />}
                        >
                          {/* Renderiza las opciones específicas para "comunidad" y "provincia" solo si es dropdown */}
                          {(input.id === "comunidad" && input.type === "dropdown") ? (
                            adminComunidades && adminComunidades.map((comunidad) => (
                              <MenuItem key={comunidad} value={comunidad}>
                                {comunidad}
                              </MenuItem>
                            ))
                          ) : (input.id === "provincia" && input.type === "dropdown") ? (
                            adminProvincias && adminProvincias.map((provincia) => (
                              <MenuItem key={provincia} value={provincia}>
                                {provincia}
                              </MenuItem>
                            ))
                          ) : null}
                        </Select>
                      </FormControl>
                    )}
                    {(input.type !== "dropdown") && ( // Renderiza el input de texto solo si no es dropdown
                      <input
                        id={input.id}
                        type={input.type}
                        placeholder={input.placeholder}
                        onChange={handleInput}
                      />
                    )}
                  </div>
                ))}
              </div>
              <div className="button-container">
                <button disabled={per !== null && per < 100} type="submit">
                  Registrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCliente;